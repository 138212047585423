window.SavedArticles = Object.assign({}, window.SavedArticles, {
  removeArticle(articleId, hideElement = null) {
    this.query("remove", articleId, hideElement);
  },
  addArticle(articleId) {
    this.query("add", articleId);
  },
  query(action, articleId, hideElement = null) {
    const self = this;
    $.ajax({
      url: interpolate("/dashboard/saved-article/%s/%s/", [articleId, action]),
      method: "GET",
      success: function (response) {
        if (action === "add") {
          flashMessage("success", gettext("Article added successfully!"));
        } else {
          flashMessage("success", gettext("Article removed from list!"));
          if (hideElement) {
            hideElement.hide();
          }
        }
      },
      error: function (err) {
        if (err && err.responseJSON && err.responseJSON.err) {
          flashMessage("danger", err.responseJSON.err);
        }
      },
    });
  },
  loadInitialState($el) {
    if ($el.closest("ul").data("skip-favorite-initial-load") !== undefined) {
      return;
    }

    let articleId = $el.data("article-id");
    $.get({
      url: interpolate("/dashboard/saved-article/%s/is-favourite/", [articleId]),
      success: function (response) {
        if (response.isFavourite === true) {
          $el.addClass("is-active");
        }
      },
      error: function (err) {
        console.log(err);
      },
    });
  },
});

if (typeof window.$ === "function") {
  window.initializeSavedArticles = function () {
    $(".add-article-to-favorite")
      .off("click")
      .on("click", function (event) {
        event.preventDefault();

        let articleId = $(this).data("article-id");
        let remove = $(this).hasClass("is-active");
        if (articleId) {
          if (remove) {
            let hideElement = $(this).data("can-remove") && $(this).closest(".global-component_article-block");
            window.SavedArticles.removeArticle(articleId, hideElement);
            $(this).removeClass("is-active");
          } else {
            window.SavedArticles.addArticle(articleId);
            $(this).addClass("is-active");
          }
        }
      })
      .each(function () {
        window.SavedArticles.loadInitialState($(this));
      });
  };
  $(document).ready(function () {
    window.initializeSavedArticles();
  });
}
